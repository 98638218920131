<script>
import {authFackMethods, authMethods} from "@/state/helpers";
import appConfig from "@/app.config";

import {required, email} from "vuelidate/lib/validators";

/**
 * Forgot Password component
 */
export default {
    page: {
        title: "Forgot Password",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {},
    data() {
        return {
            email: "",
            submitted: false,
            error: null,
            tryingToReset: false,
            isResetError: false,
            loading: false
        };
    },
    validations: {
        email: {
            required,
            email,
        },
    },
    methods: {
        ...authMethods,
        ...authFackMethods,
        // Try to register the user in with the email, fullname
        // and password they provided.
        tryToReset() {
            this.loading = true;
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.loading = false;
            } else {
                if (this.email) {
                    this.$store.dispatch('auth/forgetPassword', {email: this.email}).then(() => {
                        this.loading = false;
                    }).catch(() => {
                        this.loading = false;
                    })
                }
            }
        },
    },
};
</script>

<template>
    <div class="authentication-bg min-vh-100">
        <div class="bg-overlay bg-white"></div>
        <div class="container">
            <div class="d-flex flex-column min-vh-100 px-3 pt-4">
                <div class="row justify-content-center my-auto">
                    <div class="col-md-8 col-lg-6 col-xl-4">
                        <div class="text-center py-5">
                            <div class="mb-4 mb-md-5">
                                <router-link class="d-block auth-logo" to="/">
                                    <img
                                        alt=""
                                        class="auth-logo-dark"
                                        height="22"
                                        src="@/assets/images/logo-dark.png"
                                    />
                                    <img
                                        alt=""
                                        class="auth-logo-light"
                                        height="22"
                                        src="@/assets/images/logo-light.png"
                                    />
                                </router-link>
                            </div>
                            <div class="text-muted mb-4">
                                <h5 class="">{{ $t('login.forgot_password') }}</h5>
                                <p>{{ $t('login.reset_password') }}</p>
                            </div>
                            <div class="alert alert-success text-center mb-4" role="alert">
                                {{ $t('login.instructions_will_be_sent') }}
                            </div>
                            <b-alert
                                v-model="isResetError"
                                class="mb-4"
                                dismissible
                                variant="danger"
                            >{{ error }}
                            </b-alert
                            >
                            <form @submit.prevent="tryToReset">
                                <div class="form-floating form-floating-custom mb-3">
                                    <input
                                        id="input-email"
                                        v-model="email"
                                        :class="{ 'is-invalid': submitted && $v.email.$error }"
                                        class="form-control"
                                        placeholder="Enter Email"
                                        type="email"
                                    />
                                    <label for="input-email">{{ $t('login.email') }}</label>
                                    <div class="form-floating-icon">
                                        <i class="uil uil-envelope-alt"></i>
                                    </div>
                                    <div
                                        v-if="submitted && $v.email.$error"
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!$v.email.required">{{
                                                $t('validate.required', {item: 'Email'})
                                            }}</span>
                                        <span v-if="!$v.email.email"
                                        >{{ $t('validate.email') }}</span
                                        >
                                    </div>
                                </div>

                                <div class="mt-3">
                                    <b-button block class="w-100" variant="info" @click="tryToReset" :disabled="loading">
                                        <span v-if="!loading">{{ $t('login.password_request') }}</span>
                                        <b-spinner type="grow" v-else small></b-spinner>
                                    </b-button>
                                </div>
                                <div class="mt-3">
                                    <router-link
                                        class="text-muted text-decoration-underline"
                                        to="/login"
                                    >{{ $t('login.back_to_login') }}
                                    </router-link>
                                </div>
                            </form>
                            <!-- end form -->
                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->

                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center text-muted p-4">
                            <p class="mb-0">
                                &copy; {{ new Date().getFullYear() }} SimpleSMS. {{ $t('login.crafted_with') }}
                                <i class="mdi mdi-heart text-danger"></i> by APPON.hu
                            </p>
                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div>
        </div>
        <!-- end container -->
    </div>
    <!-- end authentication section -->
</template>

<style lang="scss" module></style>
